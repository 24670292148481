<template>
  <div id="app">
    <div class="left">
      <img src="@/assets/logo.png" style="margin-top: 3vh" />
      <p style="margin-top: 8vh; font-weight: bold; margin-bottom: 3vh">
        欢迎来到！
      </p>
      <p style="font-size: 50px; font-weight: 800">追尚助手</p>
      <p style="font-size: 50px; font-weight: 800; margin-bottom: 1rem">
        便于管理商铺
      </p>
      <span style="color: #dddddd; font-size: 1rem">
        追尚APP商家版是给广大追尚APP的商家提供的工作平台，商家们可以在这里进行一个移动办公的，可以很好的在后台和客户进行聊天的，也能及时的回复客户的问题，更有子账号建立、核销，让你即使不在店内也不会错过任何一单的生意，是商家们手机里必备的掌上管理工具。
      </span>
      <div class="btn" @click="dialogVisible = true">
        <span>探索新世界</span>
      </div>
    </div>
    <img src="./assets/网站_透明.gif" class="gif" />
    <el-dialog :visible.sync="dialogVisible">
      <div style="text-align: center">
        <img src="@/assets/QRcode.png" />
      </div>
    </el-dialog>
    <p>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" class="link">
        粤ICP备19142426号
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      dialogVisible: false,
    };
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  user-select: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* margin-top: 60px; */
  width: 100vh;
  height: 100vh;
  min-height: 600px;
  min-width: 800px;
  background-image: url("./assets/组 1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 100%;
}
.link {
  position: absolute;
  bottom: 0;
  left: 50%;
  color: #2c3e50;
  text-decoration: none;
  display: block;
  white-space: nowrap;
}
.left {
  width: 400px;
  height: 100%;
  color: white;
  position: absolute;
  left: 8%;
  transform: translate(-8%);
  z-index: 999;
  height: 100%;
  /* filter: invert(100%); */
}
.btn {
  margin-top: 5vh;
  cursor: pointer;
  width: 200px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 7px 27px 0px rgba(17, 17, 17, 0.17);
  border-radius: 26px;
  color: #f71c41;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
}
.gif {
  margin-left: 500px;
  height: 100%;
}
</style>
